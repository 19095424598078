<template>
  <v-app id="inspire">
    <navbar></navbar>
    <v-main>
      <dashboard></dashboard>
    </v-main>
  </v-app>
</template>

<script>
import Navbar from '../components/Navbar'
import Dashboard from '../components/Dashboard'
export default {  
  data: () => {return {}},
  components: {
    Navbar,
    Dashboard
  },
  computed: {
    crumbs: function () {
      let pathArray = this.$route.path.split("/");
      pathArray.shift();
      let breadcrumbs = pathArray.reduce((breadcrumbArray, path, idx) => {
        breadcrumbArray.push({
          path: path,
          to: breadcrumbArray[idx - 1]
            ? "/" + breadcrumbArray[idx - 1].path + "/" + path
            : "/" + path,
          text: this.$route.matched[idx].name || path,
        });
        return breadcrumbArray;
      }, []);
      return breadcrumbs;
    },
  }
}
</script>
