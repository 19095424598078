import Vue from 'vue';
import Router from 'vue-router';
import HomePublic from "../views/HomePublic.vue";
import HomePrivate from "../views/HomePrivate.vue";
import UserProfile from "../views/UserProfile.vue";
import Settings from "../views/Settings.vue";

Vue.use(Router)

const router = new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: [
      {
        path: "/",
        name: "Home Public",
        component: HomePublic
      },
      {
        path: "/dashboard",
        name: "Home Private",
        component: HomePrivate
      },
      {
        path: "/profile",
        name: "User Profile",
        component: UserProfile,
      },
      {
        path: "/settings",
        name: "Settings",
        component: Settings,
      }
    ]
});

export default router