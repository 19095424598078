import OpenAPIClientAxios from 'openapi-client-axios';

String.prototype.capitalize = function() {
    return this.charAt(0).toUpperCase() + this.slice(1);
}
function transformOperationId(operationId) {
    const ops = operationId.split('.').reverse()
    ops[1] = ops[1].capitalize()
    const operation = ops.join('')
    return operation
}

const api = new OpenAPIClientAxios({ 
    definition: 'https://api.home.pvri.com/definitions/smarthome-api.yaml', 
    strict: true, 
    transformOperationName: transformOperationId
});

api.init();
transformOperationId

async function getHomes(token) {
    const headers = {'Authorization': 'Bearer ' + token}
    const client = await api.getClient();
    const homes = await client.queryHomes(null, null, { headers: headers }); 
    return homes
}

async function setHome(token, home) {
    const headers = {'Authorization': 'Bearer ' + token}
    const client = await api.getClient();
    const homes = await client.updateHomes(home.id, home, { headers: headers }); 
    return homes
}

async function getDevices(token, home_id) {
    const headers = {'Authorization': 'Bearer ' + token}
    const client = await api.getClient();
    const devices = await client.queryDevices({home_id: home_id}, null, { headers: headers }); 
    return devices
}

async function getInputs(token, home_id, device_id) {
    const headers = {'Authorization': 'Bearer ' + token}
    const client = await api.getClient();
    const inputs = await client.queryInputs({home_id: home_id, device_id: device_id}, null, { headers: headers }); 
    return inputs
}

async function getOutputs(token, home_id, device_id) {
    const headers = {'Authorization': 'Bearer ' + token}
    const client = await api.getClient();
    const outputs = await client.queryOutputs({home_id: home_id, device_id: device_id}, null, { headers: headers }); 
    return outputs
}

async function getTelemetries(token, home_id, device_id, input_id, start_date, end_date) {
    const headers = {'Authorization': 'Bearer ' + token}
    const client = await api.getClient();
    const telemetries = await client.queryTelemetries({
        home_id: home_id, 
        device_id: device_id, 
        input_id: input_id, 
        start_date: start_date, 
        end_date: end_date}, 
        null, 
        { headers: headers }); 
    return telemetries
}

async function getState(token, home_id, device_id, output_id) {
    const headers = {'Authorization': 'Bearer ' + token}
    const client = await api.getClient();
    const state = await client.queryStates({home_id: home_id, device_id: device_id, output_id: output_id, end_date: (new Date().getTime()/1000)+60, limit: 1}, null, { headers: headers }); 
    return state
}

async function setState(token, home_id, device_id, output_id, state) {
    const headers = {'Authorization': 'Bearer ' + token}
    const client = await api.getClient();
    const new_state = await client.updateStates({home_id: home_id, device_id: device_id, output_id: output_id}, {state: state}, { headers: headers }); 
    return new_state
}

export { getHomes, setHome, getDevices, getInputs, getOutputs, getTelemetries, getState, setState }