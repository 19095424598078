// initial state
const state = () => ({
  user: {
      data: null
    }
})
// getters
const getters = {
    user(state) {
        return state.user.data
    },
    loggedIn(state) {
        return state.user.data != null
    },
}

// actions
const actions = {    
    async fetchUser({ commit }, user) {
        if (user) {
            commit("setUser", {
                loggedIn: true,
                displayName: user.displayName,
                email: user.email,
                avatar: user.photoURL,
                user_id: user.uid,
            });
        } else {
            commit("setUser", null);
        }
    }
}

// mutations
const mutations = {
    setUser(state, data) {
        state.user.data = data;
    }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}