import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import router from "./routes/index";
import firebase from 'firebase/app';
import "firebase/auth";
import store from "./store/store";

Vue.config.productionTip = false;

const configOptions = {
  apiKey: "AIzaSyCCRD4W5pGZXNvSCNi2DHNB8zR_trddgxk",
  authDomain: "smart-home-294217.firebaseapp.com",
  databaseURL: "https://smart-home-294217.firebaseio.com",
  projectId: "smart-home-294217",
  storageBucket: "smart-home-294217.appspot.com",
  messagingSenderId: "302601916589",
  appId: "1:302601916589:web:b645c129b0a031a6e43624",
  measurementId: "G-9S8CV5L1DT"
};

firebase.initializeApp(configOptions);

firebase.auth().onAuthStateChanged(user => {
  store.dispatch("user/fetchUser", user);
});

new Vue({
  vuetify,
  router,
  store,
  render: h => h(App)
}).$mount("#app");
