<template>
  <v-app id="inspire">
    <navbar></navbar>
    <v-main>
      <v-breadcrumbs :items="crumbs" large></v-breadcrumbs>
      <v-container>
        <v-form ref="form" v-model="valid" lazy-validation>
          <img :src="userInfo.avatar" height="256" :class="`rounded-lg`" />
          <v-text-field
            v-model="userInfo.displayName"
            label="Name"
            readonly
          ></v-text-field>
          <v-text-field
            v-model="userInfo.email"
            label="user email"
            readonly
          ></v-text-field>
          <v-select
            v-model="select"
            :items="items"
            :rules="[(v) => !!v || 'Parametro obbligatorio']"
            label="Style"
            required
          ></v-select>
          <v-btn :disabled="!valid" color="info" class="mr-4" @click="validate"
            >Save</v-btn
          >
          <v-btn color="null" class="mr-4" @click="reset">Cancel</v-btn>
        </v-form>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import Navbar from '../components/Navbar'

export default {
  name: "UserProfile",
  components: {
    Navbar
  },
  data: () => ({
    valid: true,
    number: "",
    numberRules: [
      (v) => !!v || "Parametro obbligatorio",
      (v) => (v && !isNaN(v)) || "Deve essere un numero valido",
    ],
    select: null,
    items: ["Light", "Dark", "Standard"],
    checkbox: false,
  }),
  methods: {
    validate() {
      this.$refs.form.validate();
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
  },
  computed: {
    userInfo() {
      return this.$store.getters['user/user'];
    },
  },
};
</script>
