<template>
  <div>
    <v-app-bar app color="blue" dark :src="bg" :clipped-left="$vuetify.breakpoint.lgAndUp">
      <img src="@/assets/logo.png" height="40" style="margin:20px" />
      <v-toolbar-title>Smart Home</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn icon @click="signIn()">
        <v-icon>mdi-login</v-icon>
      </v-btn>
    </v-app-bar>
    <v-container class="grey lighten-5 mb-2">
      <v-row align="center">
        <v-col md="12" align="center">
          <v-card
            class="pa-12"
            outlined
            tile
            justify="center"
          >
            <v-btn icon @click="signIn()">
                <v-icon>mdi-login</v-icon>Please login
            </v-btn>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import firebase from "firebase";

export default {
  data: () => ({
  }),
  methods: {
    signIn() {
      var provider = new firebase.auth.GoogleAuthProvider();
      firebase
        .auth()
        .signInWithPopup(provider)
        .catch(err => {
          this.error = err.message;
        });
    }
  },
  computed: {
    bg() {
      return this.background
        ? "https://cdn.vuetifyjs.com/images/backgrounds/bg-2.jpg"
        : undefined;
    },
  }
};
</script>
