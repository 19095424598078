import { getHomes, setHome, getDevices, getInputs, getOutputs } from '../../api/smarthome'
import { getToken } from '../../auth.js'

// initial state
const state = () => ({
  homes: []
})

// getters
const getters = {
    homes: (state) => {
        return state.homes
    }
}

// actions
const actions = {
  async getAllHomes ({ commit }) {
    const token = await getToken()
    var homes = (await getHomes(token)).data
    for( var home in homes) {
      homes[home].devices = (await getDevices(token, homes[home].id)).data
      for(var device in homes[home].devices) {
        console.log(homes[home].devices[device].id)
        homes[home].devices[device].inputs = (await getInputs(token, homes[home].id, homes[home].devices[device].id)).data
        homes[home].devices[device].outputs = (await getOutputs(token, homes[home].id, homes[home].devices[device].id)).data
      }
    }
    commit('loadHomes', homes)
  },
  async setHome ({ commit }, home ) {
    commit('setHome', home)
  }
}

// mutations
const mutations = {
  async loadHomes (state, homes) {
    state.homes = homes
  },

  async setHome (state, home) {    
    const token = await getToken()    
    const _home = await setHome(token, home)
    state.homes[_home.id] = _home
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}