import firebase from 'firebase/app';
import "firebase/auth";

async function getToken() {
    try {
        return await firebase.auth().currentUser.getIdToken()
    } catch (err) {
        return null
    }
}

export { getToken }