<template>
  <v-app id="inspire">
    <v-main>
      <login></login>    
    </v-main>
  </v-app>
</template>

<script>
import Login from '../components/Login'
export default {
  components: { Login },
}
</script>
