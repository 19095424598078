<template>
  <v-app id="inspire">
    <navbar></navbar>
    <v-main>
      <v-container>
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-text-field
            v-model="home.name"
            label="Name"
          ></v-text-field>
          <v-text-field
            v-model="home.address"
            label="Address"
          ></v-text-field>
          <v-btn :disabled="!valid" color="info" class="mr-4" @click="save"
            >Save</v-btn
          >
          <v-btn color="null" class="mr-4" @click="reset">Cancel</v-btn>
        </v-form>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import Navbar from '../components/Navbar'

export default {
  name: "Settings",
  components: {
    Navbar
  },
  data: () => ({
    valid: true,
    home: {},
  }),
  created() {
    this.home = this.$store.getters['home/homes'][0];
  },
  methods: {
    async save() {
      this.$refs.form.validate();
      this.$store.dispatch('home/setHome', this.home)
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
  },
  computed: {
  }
};
</script>
