<template>
  <div>
    <v-navigation-drawer
      v-model="drawer"
      :clipped="$vuetify.breakpoint.lgAndUp"
      app
      :color="color"
      :expand-on-hover="expandOnHover"
      :mini-variant="miniVariant"
      dark
    >
      <v-list dense>
        <template v-for="item in items">
          <v-row v-if="item.heading" :key="item.heading" align="center">
            <v-col cols="6">
              <v-subheader v-if="item.heading">{{ item.heading }}</v-subheader>
            </v-col>
            <v-col cols="6" class="text-center">
              <a href="#!" class="body-2 black--text">EDIT</a>
            </v-col>
          </v-row>
          <v-list-group
            v-else-if="item.children"
            :key="item.text"
            v-model="item.model"
            :prepend-icon="item.model ? item.icon : item['icon-alt']"
            append-icon
            :to="item.link"
          >
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title>{{ item.text }}</v-list-item-title>
              </v-list-item-content>
            </template>
            <v-list-item v-for="(child, i) in item.children" :key="i" link :to="child.link">
              <v-list-item-action v-if="child.icon">
                <v-icon>{{ child.icon }}</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>{{ child.text }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>
          <v-list-item v-else :key="item.text" link :to="item.link">
            <v-list-item-action>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>{{ item.text }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-list>
    </v-navigation-drawer>
    <v-app-bar 
      app
      :color="color"
      dark 
      :clipped-left="$vuetify.breakpoint.lgAndUp">
      <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
      <img src="@/assets/logo.svg" height="40" style="margin:20px" />
      <v-toolbar-title>Smart Home</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-menu offset-y v-if="loggedIn">
        <template v-slot:activator="{ on, attrs }">
          <v-list-item-avatar dark icon v-bind="attrs" v-on="on">
            <img :src="userInfo.avatar" />
          </v-list-item-avatar>
        </template>
        <v-list>
          <v-list-item>
            <v-list-item-title>{{userInfo.displayName}}</v-list-item-title>
          </v-list-item>
          <v-divider class="mx-4"></v-divider>
          <v-list-item link :to="user_profile">
            <v-list-item-title>User profile</v-list-item-title>
          </v-list-item>
          <v-list-item>
            <v-list-item-title @click="signOut()">Logout</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-btn icon @click="signIn()" v-else>
        <v-icon>mdi-login</v-icon>
      </v-btn>
    </v-app-bar>
  </div>
</template>

<script>
import firebase from "firebase";

export default {
  props: {
    source: String,
  },
  data: () => ({
    drawer: null,
    items: [
      { text: "Dashboard", icon: "mdi-view-dashboard", link: "/dashboard" },
      { text: "Settings", icon: "mdi-cog", link: "/settings" },
    ],
    user_profile: "/profile",
    color: "blue",
    right: false,
    permanent: false,
    miniVariant: true,
    expandOnHover: true,
    background: true,
    breadcrumbs: [
      {
        text: "Home",
        disabled: false,
        href: "breadcrumbs_dashboard",
      },
      {
        text: "Run List",
        disabled: true,
        href: "breadcrumbs_link_1",
      },
    ],
  }),
  methods: {
    signOut() {
      firebase
        .auth()
        .signOut()
    },
    signIn() {
      var provider = new firebase.auth.GoogleAuthProvider();
      firebase
        .auth()
        .signInWithPopup(provider)
        .catch(err => {
          this.error = err.message;
        });
    }
  },
  computed: {
    bg() {
      return this.background
        ? "https://cdn.vuetifyjs.com/images/backgrounds/bg-2.jpg"
        : undefined;
    },
    userInfo() {
      return this.$store.getters['user/user'];
    },
    loggedIn() {
      return this.$store.getters['user/loggedIn'];
    },
  },
};
</script>
