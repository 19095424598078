<template>
  <router-view />
</template>

<script>
export default {
  created() {
    this.unsubscribe = this.$store.subscribe( ( mutation, state ) => {
      if ( mutation.type === "user/setUser") {
        if( state.user.user.data != null) {  
          try {
            this.$router.push( { name: "Home Private" } )
            this.$store.dispatch("home/getAllHomes");
          } catch (err) {
            console.log(err)
          }
        } else {
          try {
            this.$router.push( { name: "Home Public" } )
          } catch (err) {
            err
          }
        }
      }
    } );
  },
  beforeDestroy() {
    this.unsubscribe()
  }
};
</script>
