<template>
  <div>
    <v-container
      class="lighten-5 mb-2"
    >
      <v-row
        align="center"
      >
        <v-col>
          <v-card
            class="pa-6"
            outlined
            tile
          >
          <v-card-title>
            {{homes[0] ? homes[0].name: ''}}
          </v-card-title>
          <v-card-text>
            <v-container fluid>
              <v-row align="center">
                <v-col
                  class="d-flex"
                  cols="12"
                  sm="6"
                >
                  <template v-for="input in input_values">
                    <v-text-field
                    v-bind:key='input.uid'
                    :value='input.data'
                    :label='input.name'
                    readonly
                    ></v-text-field>
                  </template>
                </v-col>                  
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-btn @click='ctrl_out("do2")' :class="{ red: output_states.do2, green: !output_states.do2 }" :dummy='changed'><v-icon>mdi-fan</v-icon><span v-if='output_states.do2'>stop vent</span><span v-if='!output_states.do2'>start vent</span></v-btn>
            <v-btn @click='ctrl_out("do3")' :class="{ red: output_states.do3, green: !output_states.do3 }" :dummy='changed'><v-icon>mdi-radiator</v-icon><span v-if='output_states.do3'>stop radiator</span><span v-if='!output_states.do3'>start radiator</span></v-btn>
          </v-card-actions>
          <v-card-actions>
            <v-menu
            ref="menu"
            v-model="menu"
            :close-on-content-click="false"
            :return-value.sync="dates"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="dates"
                label="Date interval"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="dates"
              no-title
              scrollable
              range
            >
              <v-spacer></v-spacer>
              <v-btn
                text
                color="primary"
                @click="menu = false"
              >
                Cancel
              </v-btn>
              <v-btn
                text
                color="primary"
                @click="$refs.menu.save(dates); get_telemetry()"
              >
                OK
              </v-btn>
            </v-date-picker>
          </v-menu>
          <v-btn
              text
              @click="get_telemetry()"
              ><v-icon>mdi-reload</v-icon>reload
            </v-btn>
          </v-card-actions>
          <v-card-text>
            <line-chart :chart-data="t_s1" :options="options"></line-chart>
          </v-card-text>
          </v-card>

        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { getTelemetries, getState, setState } from '../api/smarthome.js'
import { getToken } from '../auth.js'

import LineChart from './LineChart'
var initial_start_date = new Date(new Date().getTime()-(1000*24*3600*1)).toISOString().substring(0,10)
var initial_end_date = new Date().toISOString().substring(0,10)
export default {
  components: {
    LineChart
  },
  data: () => {return {
      t_s1: {},
      output_states: {},
      dates: [initial_start_date, initial_end_date],
      menu: false,
      changed: 0,
      input_values: null,
    }
  },
  computed: {
    ...mapState({
      homes: state => state.home.homes
    })
    ,
    options() {
      var opt = {}
      opt.scales = {
        xAxes: [{
                type: 'time',
                time: {
                    unit: 'minute',
                    displayFormats: {
                        minute: 'YYYY-MM-DD HH:mm'
                    }
                }
            }],
        yAxes: [
          {
            id: 'T',
            name: 'Temperature',
            type: 'linear',
            position: 'left',
            scalePositionLeft: false,
            ticks: {
            }
          },
          {
            id: 'H',
            name: 'Humidity',
            type: 'linear',
            position: 'right',
            scalePositionLeft: false,
            ticks: {
              //max: 100,
              //min: 0
            }
          }
        ],
      }
      return opt
    },
    dateRangeText () {
      return this.dates.join(' ~ ')
    },
  },
  async created() {
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'home/loadHomes') {
        this.get_telemetry()
        state
      }
    })
  },
  beforeDestroy() {
    if(this.unsubscribe) this.unsubscribe();
  },
  methods: {
    async get_states () {      
      const token = await getToken()
      const home = this.homes[0]
      const device = home.devices[1]
      getState(token, home.id, device.id, 'do2').then(response => {
        this.output_states.do2 = response.data[0]['state']
        this.changed++
      })
      getState(token, home.id, device.id, 'do3').then(response => {
        this.output_states.do3 = response.data[0]['state']
        this.changed++
      })
    },   
    async get_telemetry () {
      const token = await getToken()
      const home = this.homes[0]
      const start_date = new Date(this.dates[0]).getTime()/1000
      const end_date = (new Date(this.dates[1] ? this.dates[1] : this.dates[0]).getTime()/1000)+86399
      var t_p = []
      this.input_values = []
      var t_s_a = []
      
      for(var d in home.devices) {
        const device = home.devices[d]
        for(var i in device.inputs) {
          const input = device.inputs[i]
          this.input_values.push(input)
          this.input_values[this.input_values.length-1].uid = device.id+input.id
          t_p.push(getTelemetries(token, home.id, device.id, input.id, start_date, end_date))
        }
      }
      Promise.all(t_p).then(values => {
        for(var v in values) {
          const t_d = values[v].data          
          var t_s = []
          for (var t in Object.values(t_d)) {
            t_s.push({y: Math.round((t_d[t].data * 10)) / 10, x: (t_d[t].ts * 1000)})
          }
          t_s_a.push(t_s)
          if(t_d.length) this.input_values[v].data=t_d[t_d.length-1].data
        }

        this.temp = t_s_a[0][t_s_a[0].length-1].y
        this.hum = t_s_a[1][t_s_a[1].length-1].y
        this.temp_e = t_s_a[3][t_s_a[3].length-1].y
        this.hum_e = t_s_a[4][t_s_a[4].length-1].y
        this.t_s1 = {
          datasets: [
            {
              yAxisID: 'T',
              label: 'Temperature',
              fill: false,
              backgroundColor: '#f90000',
              borderColor: '#f90000',
              data: t_s_a[3]
            },
            {
              yAxisID: 'H',
              label: 'Humidity',
              fill: false,
              backgroundColor: '#0000f9',
              borderColor: '#0000f9',
              data: t_s_a[4]
            },
            {
              yAxisID: 'T',
              label: 'Ext Temperature',
              fill: false,
              backgroundColor: '#880000',
              borderColor: '#880000',
              data: t_s_a[0]
            },
            {
              yAxisID: 'H',
              label: 'Ext Humidity',
              fill: false,
              backgroundColor: '#000088',
              borderColor: '#000088',
              data: t_s_a[1]
            }
          ]
        }
      })
    },
    async ctrl_out (output) {
      const token = await getToken()
      const home = this.homes[0]
      const device = home.devices[1]
      setState(token, 
               home.id, 
               device.id, 
               output, 
               !this.output_states[output]).then(() => { this.get_states() })
    }
  }
};
</script>